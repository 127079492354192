import { environment } from '@environments/environment';
import { UseSettingConfig } from '@common/config/use-setting-config';
declare let $;
declare function webKitCallEvent(t,m):any;

export class CommonUtil {

  /**
   *  3자리수 마다 콤마
   * @param {number} num
   * @returns {string} 3자리수 마다 콤마
   */
  public static numberWithCommas(num: number): string {
    return (num) ? num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0';
  }

  /**
   *  App postmessage
   * @param {string} type -> 'gps/getPlatformType'
   * @param {string} messgae
   * @returns {void}
   */
  public static webKitCall(type: string, messgae?: string): void {
    if (messgae === undefined) {
      messgae = 'webKitCall';
    }
    try {
      webKitCallEvent(type, messgae);
    } catch (err) {
      //
    }
  }



  public static setInputFocus(target: string): void {
    if (UseSettingConfig.PARENT_PLATFORM_TYPE === 'WEB') {
      if ($('#' + target) !== null && $('#' + target) !== undefined) {
        $('#' + target).focus();
      }
    }
  }

  public static setInputFocusTimer(target: string): void {
    if (UseSettingConfig.PARENT_PLATFORM_TYPE === 'WEB') {
      window.setTimeout(() => {
        if($('#'+target) !== null && $('#'+target) !== undefined) {
          $('#'+target).focus();
        }
      },100);
    }
  }


}
