import { ErrorHandler, NgModule } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { ToastService } from '@common/service/toast.service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

@NgModule({
  imports: [
    HttpClientModule
  ],
  providers: [
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: RequestInterceptor,
    //   multi: true
    // },
    // {
    //   provide: ErrorHandler, useClass: GlobalErrorHandler
    // },
    CookieService,
    ToastService
  ]
})
export class AppServiceModule {
}
