// ionic-toast.service.ts
import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';


@Injectable({
  providedIn: 'root'
})
export class ToastService {

  private successToast: any;
  private errorToast: any;
  private warnToast: any;

  private successDuration: number = 2500;
  private errorDuration: number = 2500;

  constructor(
    private toast: ToastController
  ) { }


  showSuccessTopToast(message: string) {
    this.successToast = this.toast.create({
      message: message,
      // color: 'success',
      duration: this.successDuration,
      position: 'top',
      mode: 'ios',
      cssClass: 'ecoya-toast-success-css'
    }).then((toastData) => {
      toastData.present();
    })
  }

  showSuccessToast(message: string) {
    this.successToast = this.toast.create({
      message: message,
      // color: colorString,
      duration: this.successDuration,
      position: 'top',
      mode: 'ios',
      cssClass: 'ecoya-toast-success-css'
    }).then((toastData) => {
      toastData.present();
    });
  }

  showErrorTopToast(message: string) {
    this.errorToast = this.toast.create({
      color: 'danger',
      message: message,
      duration: this.errorDuration,
      position: 'top',
      mode: 'ios',
      cssClass: 'ecoya-toast-success-css'
    }).then((toastData) => {
      toastData.present();
    })
  }

  showErrorToast(message: string, useCss?: boolean) {
    if (useCss === undefined) {
      useCss = false;
    }
    if(useCss) {
      this.errorToast = this.toast.create({
        color: 'danger',
        message: message,
        duration: this.errorDuration,
        position: 'middle',
        mode: 'ios',
        cssClass: 'ecoya-toast-css'
      }).then((toastData) => {
        toastData.present();
      })

    } else {
      this.errorToast = this.toast.create({
        color: 'danger',
        message: message,
        duration: this.errorDuration,
        position: 'middle',
        mode: 'ios'
      }).then((toastData) => {
        toastData.present();
      })
    }
  }

  showWarnToast(message: string) {
    this.warnToast = this.toast.create({
      message: message,
      color: 'warning',
      duration: this.errorDuration,
      position: 'middle',
      mode: 'ios',
      cssClass: 'ecoya-toast-css'
    }).then((toastData) => {
      toastData.present();
    })
  }

}
