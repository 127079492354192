import { Component, ElementRef, Injector, OnDestroy, OnInit } from '@angular/core';
import { Storage } from '@ionic/storage';
import { NavController } from '@ionic/angular';
import * as $ from 'jquery';

@Component({
  selector: 'app-blank',
  templateUrl: './app.blank.component.html'
})
export class AppBlankComponent implements OnInit, OnDestroy {

  constructor(protected elementRef: ElementRef,
              protected injector: Injector,
              protected navCtrl: NavController) {
  }

  public ngOnInit(): void {
    //
  }
  public ngOnDestroy(): void {
    //
  }
  public ionViewWillEnter() {
    //
  }
  public ionViewDidEnter() {
    this.initialize();
  }
  public ionViewWillLeave() {
    //
  }
  public ionViewDidLeave() {
    //
  }
  private initialize(): void {
    window.location.href = './home';
  }

}
