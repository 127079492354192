import { Component, ElementRef, Injector, OnDestroy, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { ModalController,  Animation, AnimationController } from '@ionic/angular';
import { NavController } from '@ionic/angular';
import { LayoutNaviConstant } from '@common/constant/layout-constant';
import { Router } from '@angular/router';
import { UseSettingConfig } from '@common/config/use-setting-config';

@Component({
  selector: 'app-notification-view',
  templateUrl: './notification.view.component.html'
})
export class NotificationViewComponent implements OnInit, OnDestroy {

  @Output()
  public viewNotificationEvent = new EventEmitter();

  public isView: boolean = false;
  public notificationData: any = {
    TITLE: '',
    BODY: '',
    NOTIFICATIONS_ID: ''
  }

  private closeTimer: any = null;
  private closeDuration: number = 8000;

  constructor(protected elementRef: ElementRef,
              protected injector: Injector,
              protected router: Router,
              protected modalController: ModalController,
              protected navCtrl: NavController,
              private animationController: AnimationController) {
  }

  public ngOnInit(): void {
    //
  }
  public ngOnDestroy(): void {
    //
  }


  public setMessageData(data: any): void {
    // if (UseSettingConfig.NOTIFIACTION_LISTEN_ENABLE === false) {
    //   return;
    // }

    if (this.closeTimer !== null) {
      clearTimeout(this.closeTimer);
      this.closeTimer = null;
    }

    this.modalController.getTop().then((item) => {
      if (item !== null && item !== undefined) {
        this.modalController.dismiss(null);
      }
    });

    this.isView = false;
    this.notificationData.TITLE = data.TITLE;
    this.notificationData.BODY = data.BODY;
    this.notificationData.NOTIFICATIONS_ID = data.NOTIFICATIONS_ID;
    this.isView = true;

    const squareStart = this.animationController.create()
      .addElement(document.querySelector('.popup-message'))
      .easing('ease-out')
      .duration(200)
      .beforeStyles({ 'opacity': 1 })
      .keyframes([
        { offset: 0, transform: 'scaleX(0)'},
        { offset: 0.6, transform: 'scaleX(0.9)'},
        { offset: 1, transform: 'scaleX(1)'}
      ]);
    squareStart.play();

    let count: number = LayoutNaviConstant.MY_NEW_NOTIFICATION_NUMBER;
    count++;
    LayoutNaviConstant.MY_NEW_NOTIFICATION_NUMBER = count;


    const rurl: string = this.router.url;
    if (rurl.indexOf('none-layout/my-notification') > -1) {
      const url: string = 'none-layout/my-notification?tc='+ new Date().getTime() + '&bpage=layout-home';
      this.navCtrl.navigateForward(url);
    }

    this.closeTimer = setTimeout(() => {
      this.closeNotification();
    }, this.closeDuration);
  }


  public vewNotification(target: string): void {
    if(this.closeTimer !== null) {
      clearTimeout(this.closeTimer);
      this.closeTimer = null;
    }

    const squareEnd = this.animationController.create()
      .addElement(document.querySelector('.popup-message'))
      .easing('ease-in')
      .duration(200)
      .beforeStyles({ 'opacity': 1 })
      .keyframes([
        { offset: 0, transform: 'translateY(0vh)'},
        { offset: 1, transform: 'translateY(-100vh)'}
      ]);
    squareEnd.play();
    setTimeout(() => {
      this.isView = false;
      this.viewNotificationEvent.emit(target);
    }, 250);
  }

  public hiddenNotification(): void {
    if (this.closeTimer !== null) {
      clearTimeout(this.closeTimer);
      this.closeTimer = null;
    }
    if (this.isView) {
      this.isView = false;
    }
  }

  private closeNotification(): void {
    if (this.closeTimer !== null) {
      clearTimeout(this.closeTimer);
      this.closeTimer = null;
    }

    const squareEnd = this.animationController.create()
      .addElement(document.querySelector('.popup-message'))
      .easing('ease-in')
      .duration(200)
      .beforeStyles({ 'opacity': 1 })
      .keyframes([
        { offset: 0, transform: 'translateY(0vh)'},
        { offset: 1, transform: 'translateY(-100vh)'}
      ]);
    squareEnd.play();
    setTimeout(() => {
      this.isView = false;
    }, 250);
  }


}
