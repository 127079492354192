import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppServiceModule } from './app-service.module';
import { AppBlankComponent } from './app.blank.component';
import { CommonUserService } from '@common/service/common.user.service';
import { CryptoService } from '@common/service/crypto.service';
import { AtlanApiService } from '@common/service/atlan.api.service';
import { KakaoApiService } from '@common/service/kakao.api.service';
import { GuestGuard } from '@common/guard/guest.guard';
import { AuthUserGuard } from '@common/guard/auth.user.guard';
import { LoadingSpinnerComponent } from '@common/component/loading-spinner/loading.spinner.component';

import { NotificationViewComponent } from './app-component/notification.view.component';
import { NotificationCheckComponent } from './app-component/notification.check.component';
import { ToastService } from '@common/service/toast.service';

@NgModule({
  declarations: [
    AppComponent,
    AppBlankComponent,
    LoadingSpinnerComponent,
    NotificationViewComponent,
    NotificationCheckComponent
  ],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot(),
    AppRoutingModule,
    AppServiceModule
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    CryptoService,
    CommonUserService,
    AtlanApiService,
    KakaoApiService,
    ToastService,
    GuestGuard,
    AuthUserGuard
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
