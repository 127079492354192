import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { CommonUserService } from '@common/service/common.user.service';

@Injectable()
export class GuestGuard implements CanActivate {
  constructor(private router: Router,
              private commonUserService: CommonUserService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const enabledPromise: Promise<boolean> = this.commonUserService.isLoggedInUseGuard().then((data) => !data).catch(() => true);
    return enabledPromise;
  }
}
