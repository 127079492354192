import { Injector } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse, HttpEvent } from '@angular/common/http';
import { environment } from '@environments/environment';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';


export abstract class AbstractService {
  protected readonly API_URL: string;
  protected readonly ATLAN_API_URL: string;
  protected readonly ATLAN_MAP_AUTH_KEY: string;
  protected readonly OCR_HOST: string;

  protected readonly ESOL_PATH: string;
  // HTTP
  protected http: HttpClient;
  // Router
  protected router: Router;


  // 생성자
  protected constructor(protected injector: Injector) {
    this.router = injector.get(Router);
    this.http = injector.get(HttpClient);
    this.API_URL = '/';
    this.ATLAN_API_URL = environment.atlanMapHost + '/';
    this.ATLAN_MAP_AUTH_KEY = environment.atlanMapAuthKey;
    //
    this.ESOL_PATH = 'svc/db/ecoya/';
  }

  /**
   * GET 호출 - 로그인 이전
   */
  protected getWithoutAuth<T = any>(url: string, params?: any) {
    const options: GetTypeOption = this.getWithoutAuthRequestOptions(params);
    return this.http.get<T>(url, options);
  }


  /**
   * POST 호출 - 로그인 이전 (로그인)
   */
  protected postWithoutAuthLogin<T = any>(url: string, params?: HttpParams) {
    const options: GetTypeOption = this.getWithoutAuthRequestOptions(params);
    const result = this.http.post<T>(url, null, options);
    return result;
  }

  /**
   * POST 호출 - 로그인 이전
   */
  protected postWithoutAuth<T = any>(url: string, data?: HttpParams) {
    const options: GetTypeOption = this.getWithoutAuthRequestOptions(undefined);
    const result = this.http.post<T>(url, data, options);
    return result;
  }


  /**
   * POST 호출 - SMS 문자
   */
  protected postWithoutAuthSMS<T = any>(url: string, data: any) {
    const options: GetTypeOption = this.getWithoutAuthRequestOptions(undefined);
    return this.http.post<T>(url, data, options);
  }

  /**
   * POST 호출 - ATLAN MAP API
   */
  protected postWithoutAuthAtlan<T = any>(url: string, data: any) {
    const options: GetTypeOption = this.getWithoutAuthRequestOptions(undefined);
    return this.http.post<T>(url, data, options);
  }

  protected getWithoutAuthAtlan<T = any>(url: string) {
    // const options: GetTypeOption = this.getWithoutAuthRequestOptions(undefined);
    return this.http.get(url)
  }



  /**
   * GET 호출
   */
  protected get<T = any>(url: string, params?: any, disableLoading?: boolean) {
    const options: GetTypeOption = this.getDefaultRequestOptions(params);
    if (params) {
      options.params = params;
    }
    return this.http.get<T>(url, options);
  }

  /**
   * POST 호출
   */
  protected post<T = any>(url: string, params?: any, data?: any) {
    let requestData: any = null;
    if(data !== undefined) {
      requestData = data;
    }
    return this.http.post<T>(url, requestData, this.getDefaultRequestOptions(params)).pipe(
      catchError(error => new Observable<HttpEvent<any>>(observer => {
        // console.info('catchError');
        if (error instanceof HttpErrorResponse) {
          // console.info('error instanceof HttpErrorResponse');
          const errResp = error as HttpErrorResponse;
          // console.info('errResp', errResp);
          if (errResp.status === 401 || errResp.status === 403) {
            // this.authService.goToLogin(this.router.url);
          }
        }
        observer.error(error);
        observer.complete();
      })))
  }


  /**
   * 기본 옵션 정보 생성(without token)
   */
  protected getWithoutAuthRequestOptions(param: HttpParams) {
    // 헤더
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    let options: GetTypeOption = {};
    // 옵션
    if(param === undefined) {
      options = {headers: headers};
      return options;
    }
    options = {headers: headers, params: param};
    return options
  }

  /**
   * 기본 옵션 정보 생성
   */
  protected getDefaultRequestOptions(param: HttpParams) {
    // 헤더
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    let options: GetTypeOption = {};
    // 옵션
    if(param === undefined) {
      options = {headers: headers};
      return options;
    }
    options = {headers: headers, params: param};
    return options
  }





  protected atlanApiGet<T = any>(url: string, params?: any) {
    const options: GetTypeOption = this.getWithoutTokenAtlanApiRequestHeader();
    if (params) {
      options.params = params;
    }
    return this.http.get<T>(url, options);
  }

  /**
   * Atlan API 기본 옵션 정보 생성(without token)
   */
  protected getWithoutTokenAtlanApiRequestHeader() {
    // 헤더
    const headers = new HttpHeaders({});

    return {headers: headers};
  }
}

class GetTypeOption {
  headers?: HttpHeaders;
  observe?: 'body';
  params?: HttpParams;
  reportProgress?: boolean;
  responseType?: 'json';
  withCredentials?: boolean;
}
