import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AppBlankComponent } from './app.blank.component';
import { GuestGuard } from '@common/guard/guest.guard';
import { AuthUserGuard } from '@common/guard/auth.user.guard';

const routes: Routes = [
  {
    path: '',
    component: AppBlankComponent
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'user',
    loadChildren: () => import('./user/user.module').then(m => m.UserModule),
    canActivate: [GuestGuard]
  },
  {
    path: 'layout',
    loadChildren: () => import('./layout/layout.module').then(m => m.LayoutModule),
    canActivate: [AuthUserGuard]
  },
  {
    path: 'none-layout',
    loadChildren: () => import('./none-layout/none-layout.module').then(m => m.NoneLayoutModule),
    canActivate: [AuthUserGuard]
  },
  {
    path: 'admin-user',
    loadChildren: () => import('./admin-user/admin-user.module').then(m => m.AdminUserModule)
  },
  {
    path: '**',
    redirectTo: 'error',
    pathMatch: 'full'
  },
  {
    path: 'error',
    loadChildren: () => import('./error/error.page.module').then(m => m.ErrorPageModule)
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
