import { Injectable,Injector } from '@angular/core';
import { AbstractService } from '@common/service/abstract.service';

@Injectable()
export class KakaoApiService extends AbstractService {
  constructor(protected injector: Injector) {
    super(injector);
  }

  /**
   * 주소검색
   */
  public searchAddress(target: string) {
    // DATA
    const IN: any = {'IN': [{'ADDRESS': target}]};
    const INPUT: any = {'input' : IN};

    // URL
    const url = this.API_URL + this.ESOL_PATH + 'KAKAO_ADDRESS_SEARCH/';
    return this.post(url, undefined, INPUT);
  }

  /**
   * 좌표로 주소 검색
   */
  public searchAddressUseWgs(lat: string, lan: string) {
    // // DATA
    const IN: any = {'IN': [{'POSX': lan, 'POSY': lat}]};
    const INPUT: any = {'input' : IN};

    // URL
    const url = this.API_URL + this.ESOL_PATH + 'KAKAO_REVERSE_GEOCODING/';
    return this.post(url, undefined, INPUT);
  }


}
