import { Injectable,Injector } from '@angular/core';
import { AbstractService } from '@common/service/abstract.service';

@Injectable()
export class AtlanApiService extends AbstractService {
  constructor(protected injector: Injector) {
    super(injector);
  }

  /**
   * 주소검색
   */
  public searchAddress(target: string) {
    // URL
    const url = this.ATLAN_API_URL + 'maps/search.json?authKey=' + this.ATLAN_MAP_AUTH_KEY + '&privateKey=&schType=0&keyword=' + encodeURI(target) + '&page=1&rows=100&sort=4';
    return this.http.get(url);
  }

  /**
   * 좌표로 주소 검색
   */
  public searchAddressUseWgs(lat: string, lan: string) {
    // URL
    const url = this.ATLAN_API_URL + 'geo/coord2addr.json?authKey=' + this.ATLAN_MAP_AUTH_KEY + '&privateKey=&coordY='  + lat + '&coordX=' + lan;
    return this.http.get(url);
  }

  /**
   * 경로간 거리,시간 preview --> 화물차/버스 경로 탐색 API 로 변경
   */
  public searchFixedRoutePreview(target: any) {

    const origPosX: string = target.origPosX;
    const origPosY: string = target.origPosY;
    const destPosX: string = target.destPosX;
    const destPosY: string = target.destPosY;

    // URL - privateKey 값은 불필요 빈값'' 입니다
    // this.ATLAN_MAP_AUTH_KEY 에 할당받은 KEY 를 매칭하는 것입니다.
    let url = this.ATLAN_API_URL + 'maps/searchTruckRoute.json?authKey=' + this.ATLAN_MAP_AUTH_KEY + '&privateKey=';
    // rpType=1 (1:경로탐색, 5:간력탐색)
    url+= '&rpType=1';
    // rpOpt=32 - 경로탐색 옵션(32: 추천경로1, 64: 추천경로2)
    // 32: 화물차 추천경로1(Default), 64: 화물차 추천경로2
    // * 추천경로2는 제한구간이 있어도 제한구간을 포함한 경로안내
    url+= '&rpOpt=32';

    // truckHeight=0 - 차량 총 중량 제한 설정(0=제한없음, 20/25/30/35/40)
    url+= '&truckHeight=0';

    // avoidRoad=0 - 좁은길 회피 설정 (0=제한없음, 1/2/3/4)
    url+= '&avoidRoad=0';

    // avoidUturn=0 - 유턴 회피 설정 (0=제한없음, 4/6/8/10/12)
    url+= '&avoidUturn=0';

    // trafficOpt=0 - 실시간 교통정보 사용 여부(0: 사용, 2: 미사용)
    url+= '&trafficOpt=0';

    // carType=3
    // 0: 1종(소형차)–기본값, 1: 2종(중형차), 2: 3종(대형차)  3: 4종(대형화물차), 4: 5종(특수화물차), 5: 1종(경형자동차)
    url+= '&carType=3';

    // &carType2=3
    // 기름소모량(fuelcost) 관련 차종코드
    // 1: 1종(소형차) – 기본값, 2: 2종(중형차), 3: 3종(대형차)  4: 4종(대형화물차), 5: 5종(특수화물차), 6: 1종(경형자동차)
    url+= '&carType2=3';

    // origPosX
    url+= '&origPosX=' + origPosX;
    // origPosY
    url+= '&origPosY=' + origPosY;

    // destPosX
    url+= '&destPosX=' + destPosX;
    // destPosY
    url+= '&destPosY=' + destPosY;

    // viaCnt=0 (경유지 수)
    url+= '&viaCnt=0';

    return this.http.get(url);
  }
}
